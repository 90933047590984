import HomeBuilder from "@/components/HomeBuilder";
import HomeSegmentSelector from "@/components/HomeSegmentSelector";
import { useAdContext } from "@/contexts/AdContext";
import useFaqs from "@/hooks/useFaqs";
import useCurrentUser from "@/hooks/useCurrentUser";
import craft from "@/lib/craft";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import QueryGuestHome from "@/queries/QueryGuestHome.graphql";
import QueryArticles from "@/queries/QueryArticles.graphql";
import HomepageHero from "@/src/homepage/components/Hero";
import FaqsSection from "@components/FaqsSection";
import clsx from "clsx";
import { filter } from "lodash";
import { observer } from "mobx-react";
import Head from "next/head";
import { useEffect } from "react";
import { Seomatic } from "react-seomatic";
import { useRouter } from "next/router";
import posthog from "posthog-js";

const removeNullAttributes = (obj: any): any => {
  if (Array.isArray(obj)) {
    const filtered = filter(obj.map(removeNullAttributes), (v) => v != null);
    return filtered.length > 0 ? filtered : null;
  } else if (typeof obj === "object" && obj !== null) {
    const entries = Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, removeNullAttributes(v)]);
    return entries.length > 0 ? Object.fromEntries(entries) : null;
  }
  return obj;
};

export async function getStaticProps({ params, previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  const queries = [
    {
      document: QueryGuestHome,
    },
    {
      document: QueryArticles,
      variables: {
        limit: 12,
      },
    },
  ];

  const response = await craft(queries, preview ? previewData.token : null);

  const { pageEntry } = response[0];
  const cleanedPageEntry = removeNullAttributes(pageEntry);

  const { articles } = response[1];

  // Filter the testimonials for the homepage
  const { testimonials } = commonStaticProps;
  const testimonialsToDisplay = testimonials?.filter((testimonial) =>
    testimonial.showTestimonialOnPages.includes("homepage")
  );

  return {
    props: {
      ...commonStaticProps,
      pageEntry: cleanedPageEntry,
      articles,
      seomatic: pageEntry.seomatic,
      testimonialsToDisplay,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const Home = ({
  pageEntry,
  globalFaqs,
  articles = [],
  premiumPromotion,
  testimonials = [],
}) => {
  const router = useRouter();
  const { isLoggedIn, isLoggedOut, isFetching } = useCurrentUser();

  useEffect(() => {
    if (isLoggedIn && posthog.isFeatureEnabled("guest-homepage")) {
      router.replace("/home");
    }
  }, [isLoggedIn, isFetching, isLoggedOut]);

  // Set showAds to true for this page
  const { setShowAds } = useAdContext();
  useEffect(() => setShowAds(true));

  const faqsToDisplay = useFaqs(
    globalFaqs.items,
    pageEntry.homeFaqs,
    "homepage"
  );

  const testimonialsToDisplay = testimonials?.filter((testimonial) =>
    testimonial.showTestimonialOnPages.includes("homepage")
  );

  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />

      <div className={clsx("pb-12 md:px-6 md:pb-12 lg:px-12")}>
        <div className={clsx("overflow-hidden md:rounded-2xl")}>
          <HomepageHero />
        </div>
      </div>

      <div className={clsx("space-y-12 pb-12 lg:space-y-24")}>
        <HomeSegmentSelector segmentsConfiguration={pageEntry.segments} />

        <HomeBuilder
          blocks={pageEntry.homeBuilder}
          articles={articles}
          testimonialsToDisplay={testimonialsToDisplay}
          premiumPromotion={premiumPromotion}
        />
      </div>

      <FaqsSection
        {...globalFaqs.intro}
        faqs={faqsToDisplay}
        generateSchema
        divider={false}
      />
    </>
  );
};

export default observer(Home);
